<div class="menu-full">
  <ng-container [ngTemplateOutlet]="refMenuUL" [ngTemplateOutletContext]="{ $implicit: 'horizontal' }"></ng-container>
</div>

<ng-template #refMenuUL let-mode>
  <ul class="menu-ul">
    <ng-container *ngFor="let menu of menus">
      <ng-container *ngIf="showMenuItem(menu)" [ngTemplateOutlet]="tplMenuNgang"
        [ngTemplateOutletContext]="{ $implicit: menu }"></ng-container>
    </ng-container>

    <ng-template #tplMenuNgang let-menu>
      <ng-container *ngIf="menu.items.length === 0">
        <li [class.menu-select]="menu.route === currentRouteUrl" class="menu-li " >
          <a [routerLink]="menu.route">
            <i style="margin-right: 5px;" nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i>
            <span>{{ menu.name | localize }}</span>
          </a>
        </li>
      </ng-container>

      <li [class.menu-select]="isMenuRootItemIsActive(menu)" class="menu-li" *ngIf="menu.items.length > 0" >
        <i style="margin-right: 5px;" nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i>
        <span class="titlte-group">{{ menu!.name | localize}}</span>
        <i style="margin-left: 5px;"  *ngIf="mode === 'horizontal'" style="font-size: 12px" nz-icon nzType="down" nzTheme="outline"></i>
        
        <ul>
          <ng-container *ngFor="let menuChild of menu.items;  let last = last">
            <ng-container [ngTemplateOutlet]="tplMenuNgang"
              [ngTemplateOutletContext]="{ $implicit: menuChild }"></ng-container>
          </ng-container>
        </ul>
      </li>
    </ng-template>
  </ul>
</ng-template>
