import { Component, EventEmitter, Injector, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { ImpersonationService } from '@app/pages/admin/users/impersonation.service';
import { SettingsService } from '@delon/theme';
import { AbpSessionService } from '@node_modules/abp-ng2-module';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppConsts } from '../../../../shared/AppConsts';
import { AppMenuItem } from '../nav/app-menu-item';
import { AppNavigationService } from '../nav/app-navigation.service';
import { HeaderService } from './header-service.service';
@Component({
    selector: 'layout-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less'],
    encapsulation: ViewEncapsulation.None,
    
})
export class HeaderComponent extends AppComponentBase implements OnInit {
    searchToggleStatus: boolean;
    isImpersonatedLogin = false;
    webSiteRootAddress: string;
    expandedLog = './assets/logo-new.png';
    collapsedLogo = './assets/logo-boyte.png';
    menus: AppMenuItem[] = [];

    constructor(
        injector: Injector,
        public settings: SettingsService,
        private _abpSessionService: AbpSessionService,
        private _impersonationService: ImpersonationService,
        private _appNavigationService: AppNavigationService,
        private _headerService: HeaderService,
        private renderer: Renderer2
    ) { super(injector); }

    toggleCollapsedSidebar() {
        const collapsed = !this.settings.layout.collapsed;
        this.settings.setLayout('collapsed', collapsed);
        abp.event.trigger('abp.theme-setting.collapsed', collapsed);
        const contentElement = document.querySelector('.alain-default__content');
        const fullscreenElement = document.querySelector('.alain-fullscreen');
        
        if (this.settings.layout.collapsed) {
          this.renderer.setStyle(contentElement, 'margin-left', '5px');
        
        } else {
          this.renderer.setStyle(contentElement, 'margin-left', '40px');
        }
    }

    searchToggleChange() {
        this.searchToggleStatus = !this.searchToggleStatus;
    }

    ngOnInit(): void {
        // if(this.appSession?.tenant && this.appSession?.tenant.logoUrl){
        //     this.expandedLog = AppConsts.remoteServiceBaseUrl + `/api/FileUpload/GoToViewTempFile?url=` + this.appSession?.tenant.logoUrl;
        // }
        
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";
        
        this.menus = this._appNavigationService.getMenu()?.items;
    }

    backToMyAccount() {
        this._impersonationService.backToImpersonator();
    }

    switchMenu() {
        
            const isSmallScreen = window.innerWidth < 768;
    
            this.settings.setLayout('collapsed', isSmallScreen);
            abp.event.trigger('abp.theme-setting.collapsed', isSmallScreen);
            if (isSmallScreen) {
                this.settings.setLayout('collapsed', true);
                abp.event.trigger('abp.theme-setting.collapsed', true);
            } else {
                this.settings.setLayout('collapsed', false);
                abp.event.trigger('abp.theme-setting.collapsed', false);
            }
        
       
    }
}