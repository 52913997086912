import { Environment } from '@node_modules/@abp/ng.core';
export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static hostDomainMinio= 'https://s3.minio.orenda.vn/lms-shared-file/FolderRoot';
    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static abpEnvironment: Environment;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'PMS',
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
    };

    static readonly grid = {
        defaultPageSize: 10,
        defaultPageSizes: [5, 10, 15, 20, 25, 30, 50, 80, 100],
    };

    static webSiteRootAddress: string;
}
