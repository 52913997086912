import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, Injector, OnInit } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { AppConsts } from '@shared/AppConsts';
import { ProfileServiceProxy, SessionServiceProxy, TenantLoginInfoDto } from '@shared/service-proxies/service-proxies';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
    selector: 'header-user',
    template: `
        <!--     <div class="extension-header" style="float: left;padding: 10px">-->
        <!--         <a [href]="urlCongKhaiGia"  target="_blank">Cổng kê khai giá</a>-->
        <!--     </div>-->
        <div
            nz-dropdown
            class="alain-default__nav-item d-flex align-items-center px-sm"
            nzPlacement="bottomRight"
            [nzDropdownMenu]="userMenuTpl"
        >
            <div class="mr-sm">
                <strong>{{ shownLoginName }}</strong>
            </div>
            <nz-avatar [nzSrc]="profilePicture" nzSize="small" class="mr-sm"></nz-avatar>
        </div>
        <nz-dropdown-menu #userMenuTpl="nzDropdownMenu">
            <ul nz-menu>
                <li nz-menu-item (click)="thietLapChuKySo()">
                    <i nz-icon nzType="setting"></i>
                    Thiết lập chữ ký số
                </li>
                <li nz-menu-item (click)="clearSessionStore()">
                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                    Xóa bộ nhớ đệm
                </li>
                <li nz-menu-item (click)="changePassword()">
                    <i nz-icon nzType="ellipsis"></i>
                    {{ 'ChangePassword' | localize }}
                </li>
                <!--            <li nz-menu-item (click)="showLoginAttempts()">-->
                <!--                <i nz-icon nzType="bars"></i>-->
                <!--                {{"LoginAttempts" | localize}}-->
                <!--            </li>-->
                <!--            <li nz-menu-item (click)="changeProfilePicture()">-->
                <!--                <i nz-icon nzType="picture"></i>-->
                <!--                {{"ChangeProfilePicture" | localize}}-->
                <!--            </li>-->
                <!--            <li nz-menu-item (click)="changeMySettings()">-->
                <!--                <i nz-icon nzType="setting"></i>-->
                <!--                {{"MySettings" | localize}}-->
                <!--            </li>-->
                <li nz-menu-divider></li>
                <li nz-menu-item (click)="logout()">
                    <i nz-icon nzType="logout"></i>
                    {{ 'Logout' | localize }}
                </li>
            </ul>
        </nz-dropdown-menu>
    `,
})
export class HeaderUserComponent extends AppComponentBase implements OnInit {
    shownLoginNameTitle = '';
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    tenant: TenantLoginInfoDto = new TenantLoginInfoDto();
    urlCongKhaiGia = AppConsts.webSiteRootAddress + '/cong-khai-gia';
    constructor(
        injector: Injector,
        private authService: AppAuthService,
        private route: Router,
        private modalSrv: NzModalService,
        private messageSrv: NzMessageService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _sessionServiceProxy: SessionServiceProxy,
        private modalService: NzModalService,
    ) {
        super(injector);
    }
    ngOnInit(): void {
        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.registerToEvents();
    }

    clearSessionStore(): void {
        this.modalSrv.confirm({
            nzTitle: 'Make sure clear all local storage?',
            nzOnOk: () => {
                //this.ClearUserSessionCache();
                sessionStorage.clear();
                localStorage.clear();
                this.messageSrv.success('Clear Finished!');
                window.location.reload();
            },
        });
    }

    changePassword(): void {
        let icon = '<span id="full-screen" class="fas fa-expand full-screen"></span>';
        let sTitle = this.l('ChangePassword');
        const modal = this.modalService.create({
            nzTitle: sTitle + icon,
            nzContent: ChangePasswordModalComponent,
            nzFooter: null,
            nzWidth: '50%',
            nzMaskClosable: false,
        });

        modal.afterClose.subscribe((result) => {
            if (result) {
                this.logout();
            }
        });
    }

    thietLapChuKySo(): void {
        this.route.navigate([`app/he-thong/thiet-lap-chu-ky-so`]);
    }

    changeInfo(doanhNghiepId): void {
        // this._doanhNghiepService.getById(doanhNghiepId).subscribe(result => {
        //     this.modalHelper.create(EditDoanhNghiepComponent, { dataItem: result }).subscribe(result => {
        //     });
        // });
    }

    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });
    }

    setCurrentLoginInformations(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.userName = this.appSession.user?.userName;
        this.tenant = this.appSession.tenant;
    }

    getProfilePicture(): void {
        if (this.appSession.userId) {
            this._profileServiceProxy.getProfilePicture().subscribe((result) => {
                if (result && result.profilePicture) {
                    this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
                }
            });
        }
    }

    showLoginAttempts(): void {
        this.modalHelper.create(LoginAttemptsModalComponent).subscribe((result) => {});
    }

    changeMySettings(): void {
        this.modalHelper.createStatic(MySettingsModalComponent).subscribe((result) => {});
    }

    changeProfilePicture(): void {
        this.modalHelper.createStatic(ChangeProfilePictureModalComponent).subscribe((_) => {});
    }

    logout(): void {
        this._sessionServiceProxy.clearUserSession().subscribe();
        this.authService.logout();
    }
}
