import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,

        //Danh mục
        ApiServiceProxies.DanhMucQuocTichServiceProxy,
        ApiServiceProxies.DonViHanhChinhServiceProxy,
        ApiServiceProxies.LstDictionaryServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,
        ApiServiceProxies.LoaiTaiSanServiceProxy,
        ApiServiceProxies.DonViTinhServiceProxy,

        //Danh mục xe
        ApiServiceProxies.XeServiceProxy,

        //Quản lý tài sản
        ApiServiceProxies.PhanBoTaiSanServiceProxy,
        ApiServiceProxies.TaiSanServiceProxy,
        ApiServiceProxies.BanGiaoTaiSanServiceProxy,
        ApiServiceProxies.DieuChuyenTaiSanServiceProxy,
        ApiServiceProxies.ThuHoiTaiSanServiceProxy,

        //Phòng họp
        ApiServiceProxies.PhongHopServiceProxy,

        //Cơ sở vật chất
        ApiServiceProxies.ThietBiServiceProxy,

        //Nhân sự
        ApiServiceProxies.ChucVuServiceProxy,
        ApiServiceProxies.PhongBanServiceProxy,
        ApiServiceProxies.NhanVienServiceProxy,
        ApiServiceProxies.HoSoNhanVienServiceProxy,
        ApiServiceProxies.HopDongNhanVienServiceProxy,
        ApiServiceProxies.PhuCapKhauTruServiceProxy,
        ApiServiceProxies.LoaiHopDongServiceProxy,
        ApiServiceProxies.NhanVienNghiViecServiceProxy,
        ApiServiceProxies.HoSoBaoHiemServiceProxy,
        ApiServiceProxies.TieuChiDanhGiaServiceProxy,
        ApiServiceProxies.LoaiDanhGiaServiceProxy,
        ApiServiceProxies.NhanVienDanhGiaServiceProxy,

        //Hệ thống
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.TaiKhoanServiceProxy,
        ApiServiceProxies.TaiLieuHuongDanServiceProxy,
        ApiServiceProxies.ThietLapChungServiceProxy,

        //Ký điện tử
        ApiServiceProxies.KySoServiceProxy,
        ApiServiceProxies.ChuKyServiceProxy,
        ApiServiceProxies.QuyTrinhKyServiceProxy,
        ApiServiceProxies.QuyTrinhKyMauServiceProxy,
        ApiServiceProxies.QuyTrinhKy_CaiDatMacDinhServiceProxy,
        ApiServiceProxies.ToTrinhBienSoanServiceProxy,
        ApiServiceProxies.ToTrinhXetDuyetServiceProxy,
        ApiServiceProxies.ToTrinhBanHanhServiceProxy,
        ApiServiceProxies.ToTrinhGopYServiceProxy,

        //Lịch nghỉ phép
        ApiServiceProxies.DonNghiPhepServiceProxy,
        ApiServiceProxies.DuyetDonNghiPhepServiceProxy,

        //Lịch công tác
        ApiServiceProxies.DonCongTacServiceProxy,
        ApiServiceProxies.DuyetDonCongTacServiceProxy,

        //Lịch Họp
        ApiServiceProxies.LichHopServiceProxy,

        //Báo Cáo Tuần
        ApiServiceProxies.BaoCaoTuanServiceProxy,

        //Tổng hợp báo cáo
        ApiServiceProxies.TongHopBaoCaoServiceProxy,
        ApiServiceProxies.TongQuanCalendarServiceProxy,
        ApiServiceProxies.TongQuanGridServiceProxy,

        //Cơ sở vật chất
        ApiServiceProxies.QuanLyXeServiceProxy,
        ApiServiceProxies.BanGiaoThietBiServiceProxy,

        //Quản lý lịch họp
        ApiServiceProxies.QuanLyLichHopServiceProxy,
        ApiServiceProxies.LichHopXetDuyetServiceProxy,
        ApiServiceProxies.LichHopChoGopYServiceProxy,
        ApiServiceProxies.LichHopChoTongHopServiceProxy,
        ApiServiceProxies.LichHopChoKetLuanServiceProxy,

        //portal
        ApiServiceProxies.CategoryServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.MenuServiceProxy,
        ApiServiceProxies.MenuCategoryServiceProxy,
        ApiServiceProxies.SystemConfigAppServiveServiceProxy,
        ApiServiceProxies.BlogServiceProxy,
        ApiServiceProxies.AdvPositionServiceProxy,
        ApiServiceProxies.AdvServiceProxy,
        ApiServiceProxies.GalleryServiceProxy,
        ApiServiceProxies.VideoServiceProxy,

        //Dashboard
        ApiServiceProxies.DashboardServiceProxy,

        //desktop
        ApiServiceProxies.DesktopServiceProxy,

        //ApiServiceProxies.DashboardServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
