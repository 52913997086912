<layout-header class="alain-default__header"></layout-header>

<!-- <layout-navbar></layout-navbar> -->
<!-- <layout-sidebar class="alain-default__aside" #layoutSidebar></layout-sidebar> -->
<layout-sidebar-custom style="z-index: 99;" *ngIf="!isMenuNgang" class="alain-default__aside"></layout-sidebar-custom>
<menu-top-phan-cap *ngIf="isMenuNgang"></menu-top-phan-cap>
<section [ngClass]="{'alain-default__content': !isMenuNgang, 'wrapper__content': isMenuNgang}">
    <section class="alain-fullscreen">
        <reuse-tab [mode]="2" #reuseTab></reuse-tab>
        <router-outlet (activate)="reuseTab.activate($event)"></router-outlet>
    </section>
</section>
<ng-template #settingHost></ng-template>
<nz-back-top></nz-back-top>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>
<!--<layout-theme-btn></layout-theme-btn>-->