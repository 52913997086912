<div class="alain-default__header-logo">

    <a target="_blank" class="alain-default__header-logo-link">
        <img class="alain-default__header-logo-expanded" [src]="expandedLog" alt="{{settings.app.name}}" style="max-height:40px;" />
        <img class="alain-default__header-logo-collapsed" [src]="collapsedLogo" alt="{{settings.app.name}}" style="max-height:40px;" />
    </a>
</div>
<div class="alain-default__nav-wrap">
     
    <ul class="alain-default__nav">

        <li class="btn-sidebar" >
            <a class="mobile-menu-toggle" (click)="toggleCollapsedSidebar()">
                <i nz-icon nzType="menu-fold" nzTheme="outline"></i>
            </a>
        </li>
        <li style="font-weight: bold; text-transform: uppercase" class="hidden-mobile">
            <h1 class="title-he-thong">Hệ thống quản lý xử lý công việc cơ quan</h1>
        </li>
    </ul>

    <ul class="alain-default__nav">
       
    </ul>
    <ul class="alain-default__nav">
        <li *ngIf="isImpersonatedLogin">
            <button (click)="backToMyAccount()" nz-button nzType="link" title="Quay lại tài khoản chính">
                <i nz-icon nzType="api" nzTheme="outline"></i>
            </button>
        </li>
       
        <li>
            <header-user style="float: left;"></header-user>
        </li>
        <!-- <li>
            <language-switch></language-switch>
        </li> -->
    </ul>
</div>

<!-- <div class="menu-doc" style="display: none;">

    <ul style="width: 100%; list-style: none; padding-left: 10px;" class="menu-ul">
      <ng-container *ngFor="let menu of menus">
        <ng-container [ngTemplateOutlet]="tplMenuNgang"
          [ngTemplateOutletContext]="{ $implicit: menu }"></ng-container>
      </ng-container>
  
      <ng-template #tplMenuNgang let-menu>
  
        <ng-container *ngIf="menu.items.length === 0">
          <li>
            <a [href]="menu.route">
              <i style="margin-right: 5px;" nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i>
              <span>{{ menu.name | localize }}</span>
            </a>
          </li>
        </ng-container>
  
        <li *ngIf="menu.items.length > 0">
          <i style="margin-right: 5px;" nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i>
          <span class="titlte-group">{{ menu!.name | localize}}</span>
          <i style="margin-left: 5px;" style="font-size: 12px" nz-icon nzType="down" nzTheme="outline"></i>
          
          <ul style="list-style: none;">
            <ng-container *ngFor="let menuChild of menu.items">
              <ng-container [ngTemplateOutlet]="tplMenuNgang"
                [ngTemplateOutletContext]="{ $implicit: menuChild }"></ng-container>
            </ng-container>
          </ul>
        </li>
      </ng-template>
    </ul>
  
  </div> -->
