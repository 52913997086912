<!-- BEGIN: Aside Menu -->
<div class="alain-default__aside">
    <div class="alain-default__aside-inner">
        <ul nz-menu nzMode="inline" [nzInlineCollapsed]="collapsed">
            <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.items }"></ng-container>
            <ng-template #menuTpl let-menus>
                <ng-container *ngFor="let item of menus">
                    <ng-container *ngIf="showMenuItem(item)">
                        <li *ngIf="!(item.items && item.items.length > 0)" nz-menu-item nz-tooltip
                            nzTooltipPlacement="right" nzTooltipTitle="{{ collapsed ? (item.name | localize) : '' }}"
                            [nzSelected]="isMenuItemIsActive(item)">
                            <a [routerLink]="item.route">
                                <i class="fa-solid fa-circle" nz-icon [nzType]="item.icon" *ngIf="item.icon"></i>
                                <span>
                                    <span *ngIf="!item.icon" style="margin-right: 3px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6"
                                            fill="none">
                                            <circle cx="3" cy="3" r="3" fill="#96A5B8" />
                                        </svg>
                                    </span>
                                    {{ item.name | localize }}</span>
                            </a>
                        </li>
                        <li *ngIf="item.items && item.items.length > 0"
                            [ngClass]="isMenuRootItemIsActive(item)?'root-selected':''" nz-submenu
                            [nzOpen]="isMenuRootItemIsActive(item)" nzTitle="{{ item.name | localize }}"
                            [nzIcon]="item.icon">

                            <ul>
                                <ng-container
                                    *ngTemplateOutlet="menuTpl; context: { $implicit: item.items }"></ng-container>
                            </ul>
                        </li>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ul>
    </div>
</div>
<!-- END: Aside Menu -->