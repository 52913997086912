
import {
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';
import { AppMenu } from '../nav/app-menu';
import { AppNavigationService } from '../nav/app-navigation.service';
import { AppMenuItem } from '../nav/app-menu-item';
import { SettingsService } from '@delon/theme';
import { HeaderService } from '../layout-header/header-service.service';

@Component({
    selector: 'layout-sidebar-custom',
    templateUrl: './side-bar-menu.component.html',
    styleUrls: ['./side-bar-menu.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class SideBarMenuComponent implements OnInit {
    menu: AppMenu = null;

    currentRouteUrl = '';
    
    constructor(
        private router: Router,
        private _appNavigationService: AppNavigationService,
        public settingsSrv: SettingsService,   
    ) {
    }

    get collapsed() {
        return this.settingsSrv.layout.collapsed;
    }

    toggleCollapsed() {
        this.settingsSrv.setLayout('collapsed', !this.collapsed);
    }

    ngOnInit() {
        this.menu = this._appNavigationService.getMenu();


        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(
                (event) =>
                    (this.currentRouteUrl = this.router.url.split(/[?#]/)[0])
            );
        
        
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }

    isMenuItemIsActive(item): boolean {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.route) {
            return false;
        }

        let urlTree = this.router.parseUrl(
            this.currentRouteUrl.replace(/\/$/, '')
        );
        let urlString =
            '/' +
            urlTree.root.children.primary.segments
                .map((segment) => segment.path)
                .join('/');
        let exactMatch = urlString === item.route.replace(/\/$/, '');
        if (!exactMatch && item.routeTemplates) {
            for (let i = 0; i < item.routeTemplates.length; i++) {
                let result = new FormattedStringValueExtracter().Extract(
                    urlString,
                    item.routeTemplates[i]
                );
                if (result.IsMatch) {
                    return true;
                }
            }
        }
        return exactMatch;
    }

    isMenuRootItemIsActive(item): boolean {
        let result = false;
        for (const subItem of item.items) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }

        return false;
    }
}
