
<div [class]="isPinSidebar == true ? 'alain-default__aside' : 'alain-default__aside mini'" (mouseenter)="hoverMenu()" (mouseleave)="overMenu()"
     [ngStyle]="{'width': isPinSidebar == true ? '250px' : ''}">

    <div class="d-flex justify-content-between" style="margin-bottom:8px">
        <a class="brand-link" [routerLink]="['home']">
            <img src="assets/common/logo/logo-mobile.png"
                 alt="Logo"
                 class="brand-image"
                 style="opacity: 0.8;" />
            <span class="brand-text" [ngStyle]="{'display': isPinSidebar == true ? 'unset' : 'none'}">1School Online</span>
        </a>
        <div class="btn-toggle-footer" (click)="pinSidebar()" [ngStyle]="{'display': isPinSidebar == true ? 'unset' : '','backgroundColor': isPinSidebar == true ? '#FCB815' : ''}">
            <div class="circle-check" [ngStyle]="{'backgroundColor': isPinSidebar == true ? '#fff' : '#fff'}">
            </div>
        </div>
    </div>

    <div class="alain-default__aside-inner">
        <ul nz-menu nzMode="inline" class="menu-full">
            <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.items }"></ng-container>
            <ng-template #menuTpl let-menus>
                <ng-container *ngFor="let item of menus">
                    <ng-container *ngIf="showMenuItem(item)">
                        <li *ngIf="!(item.items && item.items.length > 0)" nz-menu-item nz-tooltip
                            nzTooltipPlacement="right" nzTooltipTitle="{{(item.name | localize)}}"
                            [nzSelected]="isMenuItemIsActive(item)" class="li-menu">
                            <a [routerLink]="item.route" class="d-flex li-menu-item">
                                <span nz-icon [nzType]="item.icon" nzTheme="outline"></span>
                                <span style="width:100%;">{{ item.name | localize }}</span>
                            </a>
                        </li>
                        <li *ngIf="item.items && item.items.length > 0"
                            [ngClass]="isMenuRootItemIsActive(item)?'root-selected':''" nz-submenu
                            [nzOpen]="isMenuRootItemIsActive(item)" nzTitle="{{ item.name | localize }}"
                            [nzIcon]="item.icon">
                            <ul *ngIf="isPinSidebar;else mobileUl">
                                <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: item.items }">
                                </ng-container>
                            </ul>
                            <ng-template #mobileUl>
                                <ul *ngIf="disableSubmenu">
                                    <ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: item.items }">
                                    </ng-container>
                                </ul>
                            </ng-template>
                        </li>
                    </ng-container>
                </ng-container>
            </ng-template>
        </ul>
    </div>
</div>
<!-- END: Aside Menu -->
