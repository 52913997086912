import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MenuService, SettingsService } from '@delon/theme';
import { Menu } from '@node_modules/@delon/theme/src/services/menu/interface';
import { AppNavigationService } from '../nav/app-navigation.service';
import { FormattedStringValueExtracter } from '@shared/helpers/FormattedStringValueExtracter';
import { AppMenuItem } from '../nav/app-menu-item';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppMenu } from '../nav/app-menu';

@Component({
    selector: 'menu-top-phan-cap',
    templateUrl: './menu-top-phan-cap.component.html',
    styleUrls: ['./menu-top-phan-cap.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MenuTopPhanCapComponent implements OnInit {
    menus: AppMenuItem[] = [];
    currentRouteUrl = '';
    item: any;
    selectedMenu: AppMenuItem | null = null;

    constructor(
        private router: Router,
        private _appNavigationService: AppNavigationService,
        public settingsSrv: SettingsService,
        private route: ActivatedRoute,


    ) {
    }

    get collapsed() {
        return this.settingsSrv.layout.collapsed;
    }

    toggleCollapsed() {
        this.settingsSrv.setLayout('collapsed', !this.collapsed);
    }

    ngOnInit() {
        this.menus = this._appNavigationService.getMenu()?.items;
        console.log(this.menus);

        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(
                (event) => {
                    this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
                    this.selectedMenu = this.findSelectedMenu(this.menus);
                }
            );
    }

    isLastChild(menu: AppMenuItem): boolean {
        const isLast = !menu.items || menu.items.length === 0;
        console.log('isLast:', isLast);
        return isLast;
    }

    findSelectedMenu(menus: AppMenuItem[]): AppMenuItem | null {
        for (const menu of menus) {
            if (menu.route === this.currentRouteUrl) {
                return menu;
            }

            if (menu.items) {
                const foundMenu = this.findSelectedMenu(menu.items);
                if (foundMenu) {
                    return foundMenu;
                }
            }
        }

        return null;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        return this._appNavigationService.showMenuItem(menuItem);
    }

    isMenuRootItemIsActive(item): boolean {
        let result = false;
        for (const subItem of item.items) {
            result = this.isMenuItemIsActive(subItem);
            if (result) {
                return true;
            }
        }

        return false;
    }

    private isMenuItemIsActive(item): boolean {
        if (item.items.length) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.route) {
            return true;
        }

        let urlTree = this.router.parseUrl(
            this.currentRouteUrl.replace(/\/$/, '')
        );
        let urlString =
            '/' +
            urlTree.root.children.primary.segments
                .map((segment) => segment.path)
                .join('/');
        let exactMatch = urlString === item.route.replace(/\/$/, '');
        if (!exactMatch && item.routeTemplates) {
            for (let i = 0; i < item.routeTemplates.length; i++) {
                let result = new FormattedStringValueExtracter().Extract(
                    urlString,
                    item.routeTemplates[i]
                );
                if (result.IsMatch) {
                    return true;
                }
            }
        }
        return exactMatch;
    }
}
