import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { AppComponent } from '@app/app.component';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        data: {
            breadcrumb: 'Trang chủ',
        },
        children: [
            {
                path: '',
                children: [
                    { path: 'notifications', component: NotificationsComponent },
                    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                ],
            },
            {
                path: 'main',
                loadChildren: () => import('./main/main.module').then((m) => m.MainModule), // Lazy load main module
                data: { preload: true },
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then((m) => m.AdminModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'cong-viec',
                loadChildren: () => import('./pages/cong-viec/cong-viec.module').then((m) => m.CongViecModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-lich-hop',
                loadChildren: () => import('./pages/quan-ly-lich-hop/quan-ly-lich-hop.module').then((m) => m.QuanLyLichHopModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'bao-cao-tuan',
                loadChildren: () => import('./pages/bao-cao-tuan/bao-cao-tuan.module').then((m) => m.BaoCaoTuanModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'co-so-vat-chat',
                loadChildren: () => import('./pages/co-so-vat-chat/co-so-vat-chat.module').then((m) => m.CoSoVatChatModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'nhan-vien',
                loadChildren: () => import('./pages/nhan-vien/nhan-vien.module').then((m) => m.NhanVienModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'tai-san',
                loadChildren: () => import('./pages/quan-ly-tai-san/tai-san.module').then((m) => m.TaiSanModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc',
                loadChildren: () => import('./pages/danh-muc/danh-muc.module').then((m) => m.DanhMucModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'he-thong',
                loadChildren: () => import('./pages/he-thong/he-thong.module').then((m) => m.HeThongModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'ky-dien-tu',
                loadChildren: () => import('./pages/ky-dien-tu/ky-dien-tu.module').then((m) => m.KyDienTuModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'cong-thong-tin',
                loadChildren: () => import('./pages/portal/portal.module').then((m) => m.PortalModule), // Lazy load portal module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            { path: '**', redirectTo: '/app/cong-viec/tong-quan' },
        ],

    },
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes)]
        RouterModule.forRoot(routes, {
            useHash: false,
            // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
            // Pls refer to https://ng-alain.com/components/reuse-tab
            // scrollPositionRestoration: 'top',
            scrollPositionRestoration: 'disabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private router: Router) { }
}
