import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { Menu, MenuService } from '@delon/theme';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'dashboard', '/app/admin/hostDashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),

            new AppMenuItem('Pages_Dashboard', 'Pages.Tenant.Dashboard', 'home', '/app/main/dashboard'),

            new AppMenuItem('Pages_CongViec', 'Pages.CongViec', 'calendar', '', [
                new AppMenuItem('Pages_CongViec_TongQuan', 'Pages.CongViec.TongQuan', '', '/app/cong-viec/tong-quan'),
                new AppMenuItem('Pages_CongViec_DonNghiPhep', 'Pages.CongViec.DonNghiPhep', '', '/app/cong-viec/don-nghi-phep'),
                //new AppMenuItem('Duyệt nghỉ phép', 'Pages.CongViec.DuyetNghiPhep', '', '/app/cong-viec/duyet-nghi-phep'),
                new AppMenuItem('Pages_CongViec_DonCongTac', 'Pages.CongViec.DonCongTac', '', '/app/cong-viec/don-cong-tac'),
                //new AppMenuItem('Duyệt công tác', 'Pages.CongViec.DuyetCongTac', '', '/app/cong-viec/duyet-cong-tac'),
                new AppMenuItem('Pages_CongViec_LichHop', 'Pages.CongViec.LichHop', '', '/app/cong-viec/lich-hop'),
                new AppMenuItem('Pages_CoSoVatChat', 'Pages.CoSoVatChat', '', '', [
                    new AppMenuItem('Pages_CoSoVatChat_QuanLyPhongHop', 'Pages.CoSoVatChat.QuanLyPhongHop', '', '/app/co-so-vat-chat/lich-hop'),
                    new AppMenuItem('Pages_CoSoVatChat_QuanLyXe', 'Pages.CoSoVatChat.QuanLyXe', '', '/app/co-so-vat-chat/quan-ly-xe'),
                ]),
            ]),

            new AppMenuItem('Pages_QuanLyLichHop', 'Pages.QuanLyLichHop', 'fund-projection-screen', '', [
                new AppMenuItem('Lịch họp mới', 'Pages.QuanLyLichHop.LichHop', '', '/app/quan-ly-lich-hop/lich-hop-moi'),
                new AppMenuItem('Lịch họp chờ duyệt', 'Pages.QuanLyLichHop.DuyetLichHop', '', '/app/quan-ly-lich-hop/lich-hop-cho-duyet'),
                new AppMenuItem('Lịch họp chờ góp ý', 'Pages.QuanLyLichHop.LichHopChoGopY', '', '/app/quan-ly-lich-hop/lich-hop-cho-gop-y'),
                new AppMenuItem('Lịch họp chờ tổng hợp', 'Pages.QuanLyLichHop.LichHopChoTongHop', '', '/app/quan-ly-lich-hop/lich-hop-cho-tong-hop'),
                new AppMenuItem('Lịch họp chờ kết luận', 'Pages.QuanLyLichHop.LichHopChoKetLuan', '', '/app/quan-ly-lich-hop/lich-hop-cho-ket-luan'),
            ]),

            new AppMenuItem('Nhân sự', 'Pages.NhanVien', 'team', '', [
                new AppMenuItem('Hồ sơ nhân viên', 'Pages.NhanVien.HoSoNhanVien', '', '/app/nhan-vien/ho-so-nhan-vien'),
                new AppMenuItem('Hợp đồng', 'Pages.NhanVien.HopDong', '', '/app/nhan-vien/hop-dong'),
                new AppMenuItem('Đánh giá', 'Pages.NhanVien.DanhGia', '', '/app/nhan-vien/danh-gia'),
                new AppMenuItem('Khen thưởng', 'Pages.NhanVien.HoSoNhanVien', '', '/app/nhan-vien/ho-so-nhan-vien'),
                new AppMenuItem('Nghỉ việc', 'Pages.NhanVien.NghiViec', '', '/app/nhan-vien/nghi-viec'),
             
                new AppMenuItem('Bảo hiểm và thuế', 'Pages.HeThong.BaoHiemVaThue', '', '', [
                    new AppMenuItem('Hồ sơ bảo hiểm', 'Pages.NhanVien.HoSoBaoHiem', '', '/app/nhan-vien/bao-hiem'),
                    new AppMenuItem('Người nộp thuế', 'Pages.NhanVien.HoSoNhanVien', '', '/app/nhan-vien/ho-so-nhan-vien'),
                    new AppMenuItem('Người phụ thuôc', 'Pages.NhanVien.HoSoNhanVien', '', '/app/nhan-vien/ho-so-nhan-vien'),
                ]),
                
            ]),

            new AppMenuItem('Quản lý văn bản', 'Pages.KyDienTu', 'folder-open', '', [
                new AppMenuItem('Văn bản trình ký', 'Pages.KyDienTu.VanBanTrinhKy', '', '/app/ky-dien-tu/van-ban-trinh-ky'),
                new AppMenuItem('Văn bản xét duyệt', 'Pages.KyDienTu.VanBanXetDuyet', '', '/app/ky-dien-tu/van-ban-xet-duyet'),
                new AppMenuItem('Văn bản ký nháy', 'Pages.KyDienTu.VanBanKyNhay', '', '/app/ky-dien-tu/van-ban-ky-nhay'),
                new AppMenuItem('Văn bản ban hành', 'Pages.KyDienTu.VanBanBanHanh', '', '/app/ky-dien-tu/van-ban-ban-hanh'),
                new AppMenuItem('Quy trình ký mẫu', 'Pages.KyDienTu.QuyTrinhKyMau', '', '/app/ky-dien-tu/quy-trinh-ky-mau'),
            ]),         

            new AppMenuItem('Tài sản', 'Pages.QuanLyTaiSan', 'control', '', [
                new AppMenuItem('Quản lý tài sản', 'Pages.QuanLyTaiSan.TaiSan', '', '/app/tai-san/quan-ly-tai-san'),
                new AppMenuItem('Phân bổ tài sản', 'Pages.QuanLyTaiSan.PhanBoTaiSan', '', '/app/tai-san/phan-bo-tai-san'),
                new AppMenuItem('Bàn giao tài sản', 'Pages.QuanLyTaiSan.BanGiaoTaiSan', '', '/app/tai-san/ban-giao-tai-san'),
                new AppMenuItem('Điều chuyển tài sản', 'Pages.QuanLyTaiSan.DieuChuyenTaiSan', '', '/app/tai-san/dieu-chuyen-tai-san'),
                new AppMenuItem('Thu hồi tài sản', 'Pages.QuanLyTaiSan.ThuHoiTaiSan', '', '/app/tai-san/thu-hoi-tai-san'),
            ]),

            new AppMenuItem('Báo cáo tuần', 'Pages.BaoCaoTuan', 'area-chart', '', [
                new AppMenuItem('Soạn báo cáo', 'Pages.BaoCaoTuan.SoanBaoBao', '', '/app/bao-cao-tuan/soan-bao-cao-tuan'),
                new AppMenuItem('Tổng hợp báo cáo', 'Pages.BaoCaoTuan.TongHopBaoCao', '', '/app/bao-cao-tuan/tong-hop-bao-cao-tuan'),
            ]),

            new AppMenuItem('Danh mục', 'Pages.DanhMuc', 'folder', '', [
                new AppMenuItem('Danh mục địa chính', 'Pages.DanhMuc.DonViHanhChinh', '', 'app/danh-muc/don-vi-hanh-chinh'),
                new AppMenuItem('Xe', 'Pages.DanhMuc.Xe', '', '/app/danh-muc/xe'),
                new AppMenuItem('Phòng họp', 'Pages.DanhMuc.PhongHop', '', '/app/danh-muc/phong-hop'),
                new AppMenuItem('Loại tài sản', 'Pages.DanhMuc.LoaiTaiSan', '', '/app/danh-muc/loai-tai-san'),
                new AppMenuItem('Đơn vị tính', 'Pages.DanhMuc.DonViTinh', '', '/app/danh-muc/don-vi-tinh'),
                new AppMenuItem('Phòng ban', 'Pages.DanhMuc.PhongBan', '', '/app/danh-muc/phong-ban'),
                new AppMenuItem('Chức vụ', 'Pages.DanhMuc.ChucVu', '', '/app/danh-muc/chuc-vu'),
                new AppMenuItem('Phụ cấp khấu trừ', 'Pages.DanhMuc.PhuCapKhauTru', '', '/app/danh-muc/phu-cap-khau-tru'),
                new AppMenuItem('Loại hợp đồng', 'Pages.DanhMuc.LoaiHopDong', '', '/app/danh-muc/loai-hop-dong'),
                new AppMenuItem('Tiêu chí đánh giá', 'Pages.DanhMuc.TieuChiDanhGia', '', '/app/danh-muc/tieu-chi-danh-gia'),
                new AppMenuItem('Loại đánh giá', 'Pages.DanhMuc.LoaiDanhGia', '', '/app/danh-muc/loai-danh-gia'),
            ]),

            new AppMenuItem('Cổng thông tin', 'Pages.Portal', 'cloud', '/app/cong-thong-tin/dashboard'),

            new AppMenuItem('Hệ thống', 'Pages.HeThong', 'appstore', '', [
                new AppMenuItem('Danh mục chung', 'Pages.HeThong.CayDanhMuc', '', '/app/he-thong/cay-danh-muc'),
                new AppMenuItem('Thiết lập chữ ký', 'Pages.HeThong.ThietLapChuKySo', '', '/app/he-thong/thiet-lap-chu-ky-so'),
                new AppMenuItem('Tài khoản người dùng', 'Pages.HeThong.QuanLyTaiKhoan', '', '/app/he-thong/tai-khoan-nguoi-dung'),
                new AppMenuItem('Quy trình ký duyệt', 'Pages.CongViec.QuyTrinhKyMau', '', '/app/he-thong/quy-trinh-ky-mau'),
                new AppMenuItem('Cài đặt hệ thống', 'Pages.HeThong.CaiDat', '', '/app/he-thong/cai-dat'),
                // new AppMenuItem('Cài đặt khác', 'Pages.HeThong.CaiDat', '', '', [
                //     new AppMenuItem('Thiết lập chung', 'Pages.HeThong.ThietLapChung', '', '/app/he-thong/thiet-lap-chung'),
                //     new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HeThong.TaiLieuHuongDan', '', '/app/he-thong/tai-lieu-huong-dan'),
                // ]),
            
            ]),

            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                new AppMenuItem('', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'book', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenantSettings'),
                new AppMenuItem('Thành phần giao diện', 'Pages.DemoUiComponents', 'html5', '/app/admin/thanh-phan-giao-dien')
            ])
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
            
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
