import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})

export class HeaderService {
    constructor() {}

    private switchMenu = new BehaviorSubject(false);

    getSwitchMenu = this.switchMenu.asObservable();

    setSwitchMenu (status: boolean) {
        this.switchMenu.next(status);
    }
}